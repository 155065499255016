import { CDN_IMAGES } from '../../constants';
import { AppThemeConfig } from '../../types/app.types';
import { Game } from '../../__generated/accounts/types';
import { Provider } from '../../types/provider';

export const config: AppThemeConfig = {
  gameName: Game.LOL,
  isPremiumSubscriptionAvailable: true,
  wallpaper: `${CDN_IMAGES}/backgrounds/desktop-onboarding-bg.png`,
  paymentWallpaper: `${CDN_IMAGES}/backgrounds/lol-payment-bg.jpg`,
  paymentCongratulationWallpaper: `${CDN_IMAGES}/backgrounds/lol-payment-congratulation-bg.jpg`,
  testimonials: [
    {
      icon: 'https://cdn.mobalytics.gg/stable/profileicon/7.png',
      name: 'Glutton, EUNE',
      text: 'testimonials-lol-glutton',
    },
    {
      icon: 'https://cdn.mobalytics.gg/stable/profileicon/2075.png',
      name: 'TeeMoDaMeeMo10, EUW',
      text: 'testimonials-lol-teemodameemo10',
    },
    {
      icon: 'https://cdn.mobalytics.gg/stable/profileicon/3887.png',
      name: 'Muhrieyuh, NA',
      text: 'testimonials-lol-muhrieyuh',
    },
    {
      icon: 'https://cdn.mobalytics.gg/stable/profileicon/3859.png',
      name: 'Game2teach, NA',
      text: 'testimonials-lol-game2teach',
    },
    {
      icon: 'https://cdn.mobalytics.gg/stable/profileicon/7.png',
      name: 'Midbeast, OCE',
      text: 'testimonials-lol-midbeast',
    },
    {
      icon: 'https://cdn.mobalytics.gg/stable/profileicon/2075.png',
      name: 'TF Blade, NA',
      text: 'testimonials-lol-blade',
    },
    {
      icon: 'https://cdn.mobalytics.gg/stable/profileicon/3887.png',
      name: 'Roguë, NA',
      text: 'testimonials-lol-rougue',
    },
    {
      icon: 'https://cdn.mobalytics.gg/stable/profileicon/3859.png',
      name: 'Charonbond, NA',
      text: 'testimonials-lol-charonbond',
    },
    {
      icon: 'https://cdn.mobalytics.gg/stable/profileicon/7.png',
      name: 'Poder, EUNE',
      text: 'testimonials-lol-poder',
    },
  ],
  primaryProviders: [Provider.Twitch, Provider.Google],
  fallbackRedirectUri: 'https://mobalytics.gg/',
};
