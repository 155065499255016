import { CDN_URL } from './config';

export const SUPPORTED_LANGUAGES = [
  'ar',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'es-ES',
  'fi',
  'fr',
  'hr',
  'it',
  'ja',
  'ko',
  'lt',
  'lv',
  'nl',
  'no',
  'pl',
  'pt-PT',
  'ru',
  'sk',
  'sl',
  'sr-CS',
  'sv-SE',
  'uk',
  'zh-CN',
  'zh-TW',
];

// localization
export const DEFAULT_LANGUAGE = 'en';
export const LANGUAGES_LOAD_PATH = '/lang/{{lng}}.json';

// links
export const TERMS_OF_SERVICE_LINK = 'https://mobalytics.gg/terms/';
export const PRIVACY_POLICY_LINK = 'https://mobalytics.gg/privacy/';

// cdn
export const CDN_IMAGES = `${CDN_URL}/assets/accounts/images`;
export const TRIAL_ONE_TIME_OFFER_LOL = 'flow_trial_offer_lol';
export const TRIAL_ONE_TIME_OFFER_TFT = 'flow_trial_offer_tft';
export const LOL_SEASON_PASS_SKIN_OFFER_COUPON = 'lol-new-season-12-discount';
