export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Account = {
  __typename?: 'Account';
  /** Attributes difined for this account by the system. Can not be changed via API. */
  attributes?: Maybe<Array<MetaDataAttributesKV>>;
  /** Auth providers connected by user */
  connectedAuthProviders?: Maybe<Array<ConnectedAuthProvider>>;
  /** Account email e.g user@example.com */
  email: Scalars['String'];
  /** User icon url, might be pressent if user signed up using one of the AuthProvider */
  iconUrl?: Maybe<Scalars['String']>;
  /** Current account level */
  level: AccountLevel;
  /**
   * Account login e.g no1one1wins.
   * - Login is not unique (there can be a lot of users with same login)
   * - Login is URL safe which meats symobls like !@#$%^&*() etc are forbidden.
   */
  login: Scalars['String'];
  /** Unique, numeric user login hash e.g 15622. Can be used it URL to identify users. */
  loginHash: Scalars['String'];
  /** Status for the referral program */
  referralStatus?: Maybe<ReferralStatus>;
  /** Unique referrer code for the referral program */
  referrerCode?: Maybe<Scalars['String']>;
  /** Stats for the referral program */
  referrerStats?: Maybe<ReferrerStats>;
  /** Account settings which can be updated via API. */
  settings?: Maybe<Array<MetaDataSettingsKV>>;
  /** Account ID e.g 14621053-f7c4-421c-a8b9-f977d33fc6a9 */
  uid: Scalars['ID'];
};

export type AccountFilter = {
  internalId?: InputMaybe<Scalars['String']>;
  loginHash?: InputMaybe<Scalars['String']>;
  referrerCode?: InputMaybe<Scalars['String']>;
  riotName?: InputMaybe<Scalars['String']>;
  tagLine?: InputMaybe<Scalars['String']>;
};

export enum AccountLevel {
  /** AdFree user is a user who have active Ad Free subscription. */
  AdFree = 'AdFree',
  /**
   * 1
   * Carry user is a user who have active Carry subscription
   */
  Carry = 'Carry',
  /** Churn user is a user who have activated trial or bout a subscription but cancel it or didn't continue. */
  Churn = 'Churn',
  /**
   * 1
   * CollectorEdition user is a user who have active CollectorEdition subscription
   */
  CollectorEdition = 'CollectorEdition',
  /** Regular user is a user who never activated trial or bot a subscription. */
  Regular = 'Regular',
  /** Supporter user is a user who have active Supporter subscription */
  Supporter = 'Supporter',
  /** Trial user is a user who have active Trial subscription */
  Trial = 'Trial',
}

export type Amount = {
  __typename?: 'Amount';
  currency?: Maybe<Currency>;
  value?: Maybe<Scalars['Int']>;
};

export enum AttributesKey {
  AB_GRPOUP = 'AB_GRPOUP',
  AUTH_CREATED_AT = 'AUTH_CREATED_AT',
  AUTH_CREATED_VIA = 'AUTH_CREATED_VIA',
  AUTH_EMAIL = 'AUTH_EMAIL',
  AUTH_EMPLOYEE = 'AUTH_EMPLOYEE',
  AUTH_EXTERNAL_DISCORD_AVATAR_URL = 'AUTH_EXTERNAL_DISCORD_AVATAR_URL',
  AUTH_EXTERNAL_DISCORD_DISCRIMINATOR = 'AUTH_EXTERNAL_DISCORD_DISCRIMINATOR',
  AUTH_EXTERNAL_DISCORD_EMAIL = 'AUTH_EXTERNAL_DISCORD_EMAIL',
  AUTH_EXTERNAL_DISCORD_ID = 'AUTH_EXTERNAL_DISCORD_ID',
  AUTH_EXTERNAL_DISCORD_USERNAME = 'AUTH_EXTERNAL_DISCORD_USERNAME',
  AUTH_EXTERNAL_FACEBOOK_AVATAR_URL = 'AUTH_EXTERNAL_FACEBOOK_AVATAR_URL',
  AUTH_EXTERNAL_FACEBOOK_EMAIL = 'AUTH_EXTERNAL_FACEBOOK_EMAIL',
  AUTH_EXTERNAL_FACEBOOK_ID = 'AUTH_EXTERNAL_FACEBOOK_ID',
  AUTH_EXTERNAL_FACEBOOK_LOCATION = 'AUTH_EXTERNAL_FACEBOOK_LOCATION',
  AUTH_EXTERNAL_FACEBOOK_NAME = 'AUTH_EXTERNAL_FACEBOOK_NAME',
  AUTH_EXTERNAL_FACEIT_AVATAR_URL = 'AUTH_EXTERNAL_FACEIT_AVATAR_URL',
  AUTH_EXTERNAL_FACEIT_EMAIL = 'AUTH_EXTERNAL_FACEIT_EMAIL',
  AUTH_EXTERNAL_FACEIT_ID = 'AUTH_EXTERNAL_FACEIT_ID',
  AUTH_EXTERNAL_FACEIT_LOCATION = 'AUTH_EXTERNAL_FACEIT_LOCATION',
  AUTH_EXTERNAL_FACEIT_MEMBERSHIP_TYPE = 'AUTH_EXTERNAL_FACEIT_MEMBERSHIP_TYPE',
  AUTH_EXTERNAL_FACEIT_NICK_NAME = 'AUTH_EXTERNAL_FACEIT_NICK_NAME',
  AUTH_EXTERNAL_FACEIT_PLATFORM_STEAM_ID = 'AUTH_EXTERNAL_FACEIT_PLATFORM_STEAM_ID',
  AUTH_EXTERNAL_FACEIT_PLATFORM_STEAM_NICK_NAME = 'AUTH_EXTERNAL_FACEIT_PLATFORM_STEAM_NICK_NAME',
  AUTH_EXTERNAL_GOOGLE_AVATAR_URL = 'AUTH_EXTERNAL_GOOGLE_AVATAR_URL',
  AUTH_EXTERNAL_GOOGLE_EMAIL = 'AUTH_EXTERNAL_GOOGLE_EMAIL',
  AUTH_EXTERNAL_GOOGLE_ID = 'AUTH_EXTERNAL_GOOGLE_ID',
  AUTH_EXTERNAL_GOOGLE_NAME = 'AUTH_EXTERNAL_GOOGLE_NAME',
  AUTH_EXTERNAL_RIOT_ACCESS_TOKEN = 'AUTH_EXTERNAL_RIOT_ACCESS_TOKEN',
  AUTH_EXTERNAL_RIOT_EMAIL = 'AUTH_EXTERNAL_RIOT_EMAIL',
  AUTH_EXTERNAL_RIOT_ID = 'AUTH_EXTERNAL_RIOT_ID',
  AUTH_EXTERNAL_RIOT_ID_TOKEN = 'AUTH_EXTERNAL_RIOT_ID_TOKEN',
  AUTH_EXTERNAL_RIOT_LOL_NAME = 'AUTH_EXTERNAL_RIOT_LOL_NAME',
  AUTH_EXTERNAL_RIOT_LOL_REGION = 'AUTH_EXTERNAL_RIOT_LOL_REGION',
  AUTH_EXTERNAL_RIOT_LOR_NAME = 'AUTH_EXTERNAL_RIOT_LOR_NAME',
  AUTH_EXTERNAL_RIOT_LOR_SERVER = 'AUTH_EXTERNAL_RIOT_LOR_SERVER',
  AUTH_EXTERNAL_RIOT_NAME = 'AUTH_EXTERNAL_RIOT_NAME',
  AUTH_EXTERNAL_RIOT_NAME_AND_TAGLINE = 'AUTH_EXTERNAL_RIOT_NAME_AND_TAGLINE',
  AUTH_EXTERNAL_RIOT_REFRESH_TOKEN = 'AUTH_EXTERNAL_RIOT_REFRESH_TOKEN',
  AUTH_EXTERNAL_RIOT_TAGLINE = 'AUTH_EXTERNAL_RIOT_TAGLINE',
  AUTH_EXTERNAL_RIOT_VAL_SERVER = 'AUTH_EXTERNAL_RIOT_VAL_SERVER',
  AUTH_EXTERNAL_STEAM_ID = 'AUTH_EXTERNAL_STEAM_ID',
  AUTH_EXTERNAL_TWITCH_AVATAR_URL = 'AUTH_EXTERNAL_TWITCH_AVATAR_URL',
  AUTH_EXTERNAL_TWITCH_BROADCASTER_TYPE = 'AUTH_EXTERNAL_TWITCH_BROADCASTER_TYPE',
  AUTH_EXTERNAL_TWITCH_CLIENT_ID = 'AUTH_EXTERNAL_TWITCH_CLIENT_ID',
  AUTH_EXTERNAL_TWITCH_CLIENT_TOKEN = 'AUTH_EXTERNAL_TWITCH_CLIENT_TOKEN',
  AUTH_EXTERNAL_TWITCH_DISPLAY_NAME = 'AUTH_EXTERNAL_TWITCH_DISPLAY_NAME',
  AUTH_EXTERNAL_TWITCH_EMAIL = 'AUTH_EXTERNAL_TWITCH_EMAIL',
  AUTH_EXTERNAL_TWITCH_ID = 'AUTH_EXTERNAL_TWITCH_ID',
  AUTH_EXTERNAL_TWITCH_LOGIN = 'AUTH_EXTERNAL_TWITCH_LOGIN',
  AUTH_EXTERNAL_TWITCH_USER_TYPE = 'AUTH_EXTERNAL_TWITCH_USER_TYPE',
  AUTH_LAST_SEEN_AT = 'AUTH_LAST_SEEN_AT',
  AUTH_NAME = 'AUTH_NAME',
  AUTH_RDT_LAST_LOGIN_AT = 'AUTH_RDT_LAST_LOGIN_AT',
  BILLING_PLAN = 'BILLING_PLAN',
  BILLING_PLAN_EXPIRES_AT = 'BILLING_PLAN_EXPIRES_AT',
  EARLY_ACCESS_PROGRAM_CSGO_ALLOWED = 'EARLY_ACCESS_PROGRAM_CSGO_ALLOWED',
  IS_TFT_ADMIN = 'IS_TFT_ADMIN',
  LESSON_PLAN = 'LESSON_PLAN',
  MARKETING_CHANNEL = 'MARKETING_CHANNEL',
  MARKETING_DISTINCT_ID = 'MARKETING_DISTINCT_ID',
  MARKETING_GAME = 'MARKETING_GAME',
  MARKETING_NEWSLETTER_ALLOWED = 'MARKETING_NEWSLETTER_ALLOWED',
  MARKETING_PLATFORM = 'MARKETING_PLATFORM',
  MARKETING_PRODUCT_UPDATES_LOL_ALLOWED = 'MARKETING_PRODUCT_UPDATES_LOL_ALLOWED',
  MARKETING_REFERRER = 'MARKETING_REFERRER',
  MARKETING_SOCIAL_ID = 'MARKETING_SOCIAL_ID',
  MARKETING_SOURCE = 'MARKETING_SOURCE',
  TFT_TEAM_COMP_PREMIUM_FILTERS_ENABLED = 'TFT_TEAM_COMP_PREMIUM_FILTERS_ENABLED',
  VERIFIED_SUMMONER = 'VERIFIED_SUMMONER',
}

/** Supported auth providers. */
export enum AuthProviderType {
  DISCORD = 'DISCORD',
  FACEBOOK = 'FACEBOOK',
  FACEIT = 'FACEIT',
  GOOGLE = 'GOOGLE',
  RIOT = 'RIOT',
  STEAM = 'STEAM',
  TWITCH = 'TWITCH',
}

export type BillingAddress = {
  __typename?: 'BillingAddress';
  city: Scalars['String'];
  country: Scalars['String'];
  line1: Scalars['String'];
  line2: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
};

export type BillingAddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  zip: Scalars['String'];
};

export type BillingCard = {
  __typename?: 'BillingCard';
  last4: Scalars['String'];
  name: Scalars['String'];
};

export type BillingInfo = {
  __typename?: 'BillingInfo';
  billing?: Maybe<BillingAddress>;
  card?: Maybe<BillingCard>;
  paypal?: Maybe<PaypalBillingInfo>;
  shipping?: Maybe<BillingAddress>;
};

export enum ChargeType {
  ANUALLY = 'ANUALLY',
  MONTHLY = 'MONTHLY',
  ONE_TIME = 'ONE_TIME',
}

/** Auth Provider connected by the user. */
export type ConnectedAuthProvider = {
  __typename?: 'ConnectedAuthProvider';
  displayName?: Maybe<Scalars['String']>;
  type: AuthProviderType;
};

export enum Currency {
  MOBA_TOKEN = 'MOBA_TOKEN',
  USD = 'USD',
}

export type Duration = {
  __typename?: 'Duration';
  count: Scalars['Int'];
  unit: DurationUnit;
};

export enum DurationUnit {
  days = 'days',
  hours = 'hours',
  milliseconds = 'milliseconds',
  minutes = 'minutes',
  month = 'month',
  months = 'months',
  seconds = 'seconds',
  weeks = 'weeks',
  years = 'years',
}

export enum Errors {
  AUTH_ALREADY_AUTHENTICATED = 'AUTH_ALREADY_AUTHENTICATED',
  AUTH_CREDENTIALS_INVALID = 'AUTH_CREDENTIALS_INVALID',
  AUTH_EMAIL_ALREADY_EXISTS = 'AUTH_EMAIL_ALREADY_EXISTS',
  AUTH_EXTERNAL_CONFLICT = 'AUTH_EXTERNAL_CONFLICT',
  AUTH_EXTERNAL_EMAIL_MISSING = 'AUTH_EXTERNAL_EMAIL_MISSING',
  AUTH_EXTERNAL_PLAUSIBLE_MATCH = 'AUTH_EXTERNAL_PLAUSIBLE_MATCH',
  CARD_DECLINED = 'CARD_DECLINED',
  CARD_LIMIT_EXCEEDED = 'CARD_LIMIT_EXCEEDED',
  COUPON_INVALID = 'COUPON_INVALID',
  EMAIL_INVALID = 'EMAIL_INVALID',
  EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
  EXPIRED_CARD = 'EXPIRED_CARD',
  FORBIDDEN = 'FORBIDDEN',
  INCORRECT_CVC = 'INCORRECT_CVC',
  INCORRECT_NUMBER = 'INCORRECT_NUMBER',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  NAME_INVALID = 'NAME_INVALID',
  NOT_ENOUGH_TOKENS = 'NOT_ENOUGH_TOKENS',
  PASSWORD_CONFIRMATION_DOESNT_MATCH = 'PASSWORD_CONFIRMATION_DOESNT_MATCH',
  PASSWORD_IS_EMPTY = 'PASSWORD_IS_EMPTY',
  PASSWORD_TOO_LONG = 'PASSWORD_TOO_LONG',
  PASSWORD_TOO_SHORT = 'PASSWORD_TOO_SHORT',
  PAYMENT_COUPON_INVALID = 'PAYMENT_COUPON_INVALID',
  PAYMENT_DECLINED = 'PAYMENT_DECLINED',
  PROCESSING_ERROR = 'PROCESSING_ERROR',
  SUMMONER_DOES_NOT_EXIST = 'SUMMONER_DOES_NOT_EXIST',
  TOKEN_INVALID = 'TOKEN_INVALID',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  UNDEFINED_TOKEN_OPERATION = 'UNDEFINED_TOKEN_OPERATION',
}

/** Supported games. */
export enum Game {
  CSGO = 'CSGO',
  DEADLOCK = 'DEADLOCK',
  DESTINY_2 = 'DESTINY_2',
  DIABLO_4 = 'DIABLO_4',
  LOL = 'LOL',
  LOR = 'LOR',
  LOST_ARK = 'LOST_ARK',
  OMEN = 'OMEN',
  POE_2 = 'POE_2',
  TFT = 'TFT',
  THE_BAZAAR = 'THE_BAZAAR',
  VALORANT = 'VALORANT',
}

export enum Languages {
  AR_AR = 'AR_AR',
  CS_CZ = 'CS_CZ',
  DE_DE = 'DE_DE',
  EL_GR = 'EL_GR',
  EN_US = 'EN_US',
  ES_ES = 'ES_ES',
  FR_FR = 'FR_FR',
  HU_HU = 'HU_HU',
  IT_IT = 'IT_IT',
  JA_JP = 'JA_JP',
  KO_KR = 'KO_KR',
  PL_PL = 'PL_PL',
  PT_BR = 'PT_BR',
  RO_RO = 'RO_RO',
  RU_RU = 'RU_RU',
  TR_TR = 'TR_TR',
  ZH_CHS = 'ZH_CHS',
}

export type LeaderboardFilter = {
  period?: InputMaybe<LeaderboardPeriod>;
};

export type LeaderboardItem = {
  __typename?: 'LeaderboardItem';
  participant: LeaderboardParticipant;
  position: Scalars['Int'];
  referralsCount: Scalars['Int'];
};

export type LeaderboardParticipant = {
  __typename?: 'LeaderboardParticipant';
  referrerCode: Scalars['String'];
  region: LolGameRegion;
  summonerName: Scalars['String'];
};

export enum LeaderboardPeriod {
  LAST_MONTH = 'LAST_MONTH',
  UNDEFINED = 'UNDEFINED',
}

export enum LolGameRegion {
  BR = 'BR',
  EUNE = 'EUNE',
  EUW = 'EUW',
  JP = 'JP',
  KR = 'KR',
  LAN = 'LAN',
  LAS = 'LAS',
  ME = 'ME',
  NA = 'NA',
  OCE = 'OCE',
  PBE = 'PBE',
  PH = 'PH',
  RU = 'RU',
  SG = 'SG',
  TH = 'TH',
  TR = 'TR',
  TW = 'TW',
  UNDEFINED = 'UNDEFINED',
  VN = 'VN',
}

export enum LolQueueType {
  NORMAL_BLIND = 'NORMAL_BLIND',
  NORMAL_DRAFT = 'NORMAL_DRAFT',
  RANKED_FLEX = 'RANKED_FLEX',
  RANKED_SOLO = 'RANKED_SOLO',
}

export enum LolRolename {
  ADC = 'ADC',
  JUNGLE = 'JUNGLE',
  MID = 'MID',
  SUPPORT = 'SUPPORT',
  TOP = 'TOP',
}

export enum LorServer {
  AMERICAS = 'AMERICAS',
  APAC = 'APAC',
  ASIA = 'ASIA',
  EUROPE = 'EUROPE',
  SEA = 'SEA',
}

export type MetaDataAttributesKV = {
  __typename?: 'MetaDataAttributesKV';
  /** Meta data creation date in ISO format e.g 2020-05-05T09:38:33Z */
  createdAt?: Maybe<Scalars['String']>;
  /**
   * Meta data key, max length 255 symbols. It is required to set API scope as key prefix.
   * E.g for LoL Game key game.lol.MY_KEY.
   */
  key: AttributesKey;
  /** Meta data last update date in ISO format e.g 2020-05-05T09:38:33Z */
  updatedAt?: Maybe<Scalars['String']>;
  /** Meta data value, max length 255 symbols, you can use arbitrary format. */
  value: Scalars['String'];
};

export type MetaDataSettingsKV = {
  __typename?: 'MetaDataSettingsKV';
  /** Meta data creation date in ISO format e.g 2020-05-05T09:38:33Z */
  createdAt?: Maybe<Scalars['String']>;
  /**
   * Meta data key, max length 255 symbols. It is required to set API scope as key prefix.
   * E.g for LoL Game key game.lol.MY_KEY.
   */
  key: SettingsKey;
  /** Meta data last update date in ISO format e.g 2020-05-05T09:38:33Z */
  updatedAt?: Maybe<Scalars['String']>;
  /** Meta data value, max length 255 symbols, you can use arbitrary format. */
  value: Scalars['String'];
};

export type MetaDataSettingsKVInput = {
  key: SettingsKey;
  value: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelCurrentSubscription: Scalars['Boolean'];
  createSubscription?: Maybe<Array<SubscriptionPlan>>;
  deleteAccount: Scalars['Boolean'];
  deleteCustomerInfo: Scalars['Boolean'];
  disconnectProvider: Scalars['Boolean'];
  experiment3DLCActiveTrialPlus: Scalars['Boolean'];
  payments?: Maybe<Payments>;
  requestPasswordReset: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  signIn: Scalars['Boolean'];
  signOut: Scalars['Boolean'];
  signUp: Scalars['Boolean'];
  updateAccountInfo: Account;
  updateCustomer: Scalars['Boolean'];
  updatePassword: Scalars['Boolean'];
  updateShippingInfo: Scalars['Boolean'];
  updateSubscription?: Maybe<Array<SubscriptionPlan>>;
  validateCaptcha: Scalars['Boolean'];
  verifyReferral: ReferralStatus;
};

export type MutationcancelCurrentSubscriptionArgs = {
  additionalInfo: Scalars['String'];
  reason: Array<Scalars['String']>;
};

export type MutationcreateSubscriptionArgs = {
  Token: Scalars['String'];
  coupon?: InputMaybe<Scalars['String']>;
  kind: PaymentGatewayKind;
  payPalSpecific?: InputMaybe<PayPalSpecific>;
  planId: Scalars['ID'];
};

export type MutationdisconnectProviderArgs = {
  type: AuthProviderType;
};

export type MutationrequestPasswordResetArgs = {
  email: Scalars['String'];
  game: Game;
  lang: Scalars['String'];
  redirectUrl: Scalars['String'];
};

export type MutationresetPasswordArgs = {
  password1: Scalars['String'];
  token: Scalars['String'];
};

export type MutationsignInArgs = {
  continueFrom?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationsignUpArgs = {
  continueFrom?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  marketing?: InputMaybe<SingUpMarketingParams>;
  marketingNewsletterAllowed?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  password: Scalars['String'];
  referrerId?: InputMaybe<Scalars['String']>;
};

export type MutationupdateAccountInfoArgs = {
  email?: InputMaybe<Scalars['String']>;
  login?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Array<MetaDataSettingsKVInput>>;
};

export type MutationupdateCustomerArgs = {
  tokenId: Scalars['String'];
};

export type MutationupdatePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type MutationupdateShippingInfoArgs = {
  shippingAddress: BillingAddressInput;
  source: PaymentGatewayKind;
};

export type MutationupdateSubscriptionArgs = {
  coupon?: InputMaybe<Scalars['String']>;
  planId: Scalars['ID'];
};

export type MutationvalidateCaptchaArgs = {
  token: Scalars['String'];
};

export type MutationverifyReferralArgs = {
  level: Scalars['Int'];
  riotId: Scalars['Int'];
};

export type PayPalSpecific = {
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  line1: Scalars['String'];
  line2: Scalars['String'];
  payerId: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
};

export enum PaymentGatewayKind {
  Paypal = 'Paypal',
  Stripe = 'Stripe',
}

export type PaymentResult = {
  __typename?: 'PaymentResult';
  plans?: Maybe<Array<SubscriptionPlan>>;
};

export type Payments = {
  __typename?: 'Payments';
  processPayment: PaymentResult;
  updatePaymentInfo: UpdatePaymentInfoResult;
};

export type PaymentsprocessPaymentArgs = {
  Token?: InputMaybe<Scalars['String']>;
  coupon?: InputMaybe<Scalars['String']>;
  kind: PaymentGatewayKind;
  payPalSpecific?: InputMaybe<PayPalSpecific>;
  planId: Scalars['ID'];
};

export type PaymentsupdatePaymentInfoArgs = {
  Token?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<PaymentGatewayKind>;
};

export type PaypalBillingInfo = {
  __typename?: 'PaypalBillingInfo';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  payerId: Scalars['String'];
};

export type PublicUserAccount = {
  __typename?: 'PublicUserAccount';
  /** Attributes difined for this account by the system. Can not be changed via API. */
  attributes?: Maybe<Array<MetaDataAttributesKV>>;
  /** User icon url, might be pressent if user signed up using one of the AuthProvider */
  iconUrl?: Maybe<Scalars['String']>;
  /** Current account level */
  level: AccountLevel;
  /**
   * Account login e.g no1one1wins.
   * - Login is not unique (there can be a lot of users with same login)
   * - Login is URL safe which meats symobls like !@#$%^&*() etc are forbidden.
   */
  login: Scalars['String'];
  /** Unique, numeric user login hash e.g 15622. Can be used it URL to identify users. */
  loginHash: Scalars['String'];
  /** Account settings which can be updated via API. */
  settings?: Maybe<Array<MetaDataSettingsKV>>;
  /** Account ID e.g 14621053-f7c4-421c-a8b9-f977d33fc6a9 */
  uid: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  auth?: Maybe<Session>;
  getPlans?: Maybe<Array<SubscriptionPlan>>;
  publicUserAccount?: Maybe<PublicUserAccount>;
  redirectToken: Session;
  referral: Referral;
  session: Scalars['Boolean'];
  subscriptions?: Maybe<Array<SubscriptionPlan>>;
  wallet?: Maybe<Wallet>;
};

export type QuerygetPlansArgs = {
  coupon: Scalars['String'];
};

export type QuerypublicUserAccountArgs = {
  filters?: InputMaybe<AccountFilter>;
};

export type RaffleFilter = {
  month?: InputMaybe<RaffleMonthInput>;
};

export type RaffleItem = {
  __typename?: 'RaffleItem';
  participant?: Maybe<RaffleParticipant>;
  position: Scalars['Int'];
  rpAmount: Scalars['Int'];
};

export type RaffleMonth = {
  __typename?: 'RaffleMonth';
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type RaffleMonthInput = {
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type RaffleParticipant = {
  __typename?: 'RaffleParticipant';
  region: LolGameRegion;
  summonerName: Scalars['String'];
};

export type Referral = {
  __typename?: 'Referral';
  leaderboard?: Maybe<Array<LeaderboardItem>>;
  raffle?: Maybe<Array<RaffleItem>>;
  raffleMonths?: Maybe<Array<RaffleMonth>>;
};

export type ReferralleaderboardArgs = {
  filter?: InputMaybe<LeaderboardFilter>;
};

export type ReferralraffleArgs = {
  filter?: InputMaybe<RaffleFilter>;
};

export enum ReferralStatus {
  CONFIRMED = 'CONFIRMED',
  PENDING = 'PENDING',
  REJECTED_DUPLICATED_ID = 'REJECTED_DUPLICATED_ID',
  REJECTED_LOW_LEVEL = 'REJECTED_LOW_LEVEL',
  UNDEFINED = 'UNDEFINED',
}

export type ReferrerStats = {
  __typename?: 'ReferrerStats';
  earnedPremiumDays: Scalars['Int'];
  lastMonthReferralsCount: Scalars['Int'];
  referralsCount: Scalars['Int'];
};

export type Session = {
  __typename?: 'Session';
  token?: Maybe<Scalars['String']>;
};

export enum SettingsKey {
  core_onboarding_info = 'core_onboarding_info',
  core_profile_discord_url = 'core_profile_discord_url',
  core_profile_name = 'core_profile_name',
  core_profile_steam_id = 'core_profile_steam_id',
  core_profile_twitch_url = 'core_profile_twitch_url',
  core_profile_x_url = 'core_profile_x_url',
  core_profile_youtube_url = 'core_profile_youtube_url',
  game_bacon_AUTH_RDT_LAST_LOGIN_AT = 'game_bacon_AUTH_RDT_LAST_LOGIN_AT',
  game_bacon_is_private_account = 'game_bacon_is_private_account',
  game_bacon_playerName = 'game_bacon_playerName',
  game_bacon_playerServer = 'game_bacon_playerServer',
  game_diablo_profile_discord_url = 'game_diablo_profile_discord_url',
  game_diablo_profile_twitch_url = 'game_diablo_profile_twitch_url',
  game_diablo_profile_twitter_url = 'game_diablo_profile_twitter_url',
  game_diablo_profile_youtube_url = 'game_diablo_profile_youtube_url',
  game_lol_default_queue = 'game_lol_default_queue',
  game_lol_default_rolename = 'game_lol_default_rolename',
  game_lol_email_subscriptions = 'game_lol_email_subscriptions',
  game_lol_experiment_3DLC_CHAMPION_SKIN_OFFER_USED = 'game_lol_experiment_3DLC_CHAMPION_SKIN_OFFER_USED',
  game_lol_experiment_3DLC_GOAL_1_ACHIEVED = 'game_lol_experiment_3DLC_GOAL_1_ACHIEVED',
  game_lol_experiment_3DLC_GOAL_2_ACHIEVED = 'game_lol_experiment_3DLC_GOAL_2_ACHIEVED',
  game_lol_experiment_3DLC_GOAL_3_ACHIEVED = 'game_lol_experiment_3DLC_GOAL_3_ACHIEVED',
  game_lol_experiment_3DLC_OFFER_USED = 'game_lol_experiment_3DLC_OFFER_USED',
  game_lol_lc_HAS_CONFIRMED_IMPORT_SETTINGS = 'game_lol_lc_HAS_CONFIRMED_IMPORT_SETTINGS',
  game_lol_lc_widgets_AD_PLACEMENT = 'game_lol_lc_widgets_AD_PLACEMENT',
  game_lol_lc_widgets_BANNED_CHAMPIONS = 'game_lol_lc_widgets_BANNED_CHAMPIONS',
  game_lol_lc_widgets_BAN_RECOMMENDATION = 'game_lol_lc_widgets_BAN_RECOMMENDATION',
  game_lol_lc_widgets_CHALLENGES = 'game_lol_lc_widgets_CHALLENGES',
  game_lol_lc_widgets_CHAMPION_POWER_SPIKES = 'game_lol_lc_widgets_CHAMPION_POWER_SPIKES',
  game_lol_lc_widgets_COMBOS = 'game_lol_lc_widgets_COMBOS',
  game_lol_lc_widgets_CURRENT_SESSION = 'game_lol_lc_widgets_CURRENT_SESSION',
  game_lol_lc_widgets_GAME_PLAN = 'game_lol_lc_widgets_GAME_PLAN',
  game_lol_lc_widgets_GANK_OPPORTUNITIES = 'game_lol_lc_widgets_GANK_OPPORTUNITIES',
  game_lol_lc_widgets_PICK_RECOMMENDATION = 'game_lol_lc_widgets_PICK_RECOMMENDATION',
  game_lol_lc_widgets_PLAYING_AGAINST = 'game_lol_lc_widgets_PLAYING_AGAINST',
  game_lol_lc_widgets_TIER_LIST = 'game_lol_lc_widgets_TIER_LIST',
  game_lol_lc_widgets_aram_post_game_TEAMS_GENERAL_STATS = 'game_lol_lc_widgets_aram_post_game_TEAMS_GENERAL_STATS',
  game_lol_lc_widgets_aram_pre_game_COMBOS = 'game_lol_lc_widgets_aram_pre_game_COMBOS',
  game_lol_lc_widgets_aram_pre_game_CURRENT_SESSION = 'game_lol_lc_widgets_aram_pre_game_CURRENT_SESSION',
  game_lol_lc_widgets_aram_pre_game_SELECTED_CHAMPIONS = 'game_lol_lc_widgets_aram_pre_game_SELECTED_CHAMPIONS',
  game_lol_lc_widgets_home_GO_DEEPER_WITH_OPPONENT_INSIGHTS = 'game_lol_lc_widgets_home_GO_DEEPER_WITH_OPPONENT_INSIGHTS',
  game_lol_lc_widgets_home_HOME_MY_CHAMPIONS = 'game_lol_lc_widgets_home_HOME_MY_CHAMPIONS',
  game_lol_lc_widgets_home_HOME_NEW_CHAMPION_TO_TRY = 'game_lol_lc_widgets_home_HOME_NEW_CHAMPION_TO_TRY',
  game_lol_lc_widgets_home_HOME_SKILL_TO_FOCUS = 'game_lol_lc_widgets_home_HOME_SKILL_TO_FOCUS',
  game_lol_lc_widgets_home_HOME_TIER_LIST_HIGHLIGHTS = 'game_lol_lc_widgets_home_HOME_TIER_LIST_HIGHLIGHTS',
  game_lol_lc_widgets_home_PROMO_PREMIUM_LC_VIDEO = 'game_lol_lc_widgets_home_PROMO_PREMIUM_LC_VIDEO',
  game_lol_lc_widgets_home_PROMO_TOKENS_INTRO = 'game_lol_lc_widgets_home_PROMO_TOKENS_INTRO',
  game_lol_lc_widgets_lobby_BANNED_CHAMPIONS = 'game_lol_lc_widgets_lobby_BANNED_CHAMPIONS',
  game_lol_lc_widgets_lobby_BAN_RECOMMENDATION = 'game_lol_lc_widgets_lobby_BAN_RECOMMENDATION',
  game_lol_lc_widgets_lobby_COMBO_TRADING_AT_LANE = 'game_lol_lc_widgets_lobby_COMBO_TRADING_AT_LANE',
  game_lol_lc_widgets_lobby_CURRENT_SESSION_STATS = 'game_lol_lc_widgets_lobby_CURRENT_SESSION_STATS',
  game_lol_lc_widgets_lobby_DAMAGE_TYPE_DISTR = 'game_lol_lc_widgets_lobby_DAMAGE_TYPE_DISTR',
  game_lol_lc_widgets_lobby_GANK_OPPORTUNITIES = 'game_lol_lc_widgets_lobby_GANK_OPPORTUNITIES',
  game_lol_lc_widgets_lobby_ITEMS_RECOMMENDATION = 'game_lol_lc_widgets_lobby_ITEMS_RECOMMENDATION',
  game_lol_lc_widgets_lobby_KEY_SUMMONER_INSIGHTS = 'game_lol_lc_widgets_lobby_KEY_SUMMONER_INSIGHTS',
  game_lol_lc_widgets_lobby_PICKS_AND_BANS_RECS = 'game_lol_lc_widgets_lobby_PICKS_AND_BANS_RECS',
  game_lol_lc_widgets_lobby_PICK_RECOMMENDATION = 'game_lol_lc_widgets_lobby_PICK_RECOMMENDATION',
  game_lol_lc_widgets_lobby_PROMO_TOKENS_INTRO = 'game_lol_lc_widgets_lobby_PROMO_TOKENS_INTRO',
  game_lol_lc_widgets_lobby_RUNES_AND_ITEMS_AUTO_IMPORT = 'game_lol_lc_widgets_lobby_RUNES_AND_ITEMS_AUTO_IMPORT',
  game_lol_lc_widgets_lobby_RUNES_RECOMMENDATION = 'game_lol_lc_widgets_lobby_RUNES_RECOMMENDATION',
  game_lol_lc_widgets_lobby_SKILL_ORDER = 'game_lol_lc_widgets_lobby_SKILL_ORDER',
  game_lol_lc_widgets_lobby_SPELLS_RECOMMENDATION = 'game_lol_lc_widgets_lobby_SPELLS_RECOMMENDATION',
  game_lol_lc_widgets_overview_BAN_RECOMMENDATION = 'game_lol_lc_widgets_overview_BAN_RECOMMENDATION',
  game_lol_lc_widgets_overview_GO_DEEPER_WITH_OPPONENT_INSIGHTS = 'game_lol_lc_widgets_overview_GO_DEEPER_WITH_OPPONENT_INSIGHTS',
  game_lol_lc_widgets_overview_MY_CHAMPIONS = 'game_lol_lc_widgets_overview_MY_CHAMPIONS',
  game_lol_lc_widgets_overview_NEW_CHAMPION_TO_TRY = 'game_lol_lc_widgets_overview_NEW_CHAMPION_TO_TRY',
  game_lol_lc_widgets_overview_PROMO_TOKENS_INTRO = 'game_lol_lc_widgets_overview_PROMO_TOKENS_INTRO',
  game_lol_lc_widgets_overview_SKILL_TO_FOCUS = 'game_lol_lc_widgets_overview_SKILL_TO_FOCUS',
  game_lol_lc_widgets_overview_TIER_LIST_HIGHLIGHTS = 'game_lol_lc_widgets_overview_TIER_LIST_HIGHLIGHTS',
  game_lol_lc_widgets_post__game_INCOME_OVERVIEW = 'game_lol_lc_widgets_post__game_INCOME_OVERVIEW',
  game_lol_lc_widgets_post__game_PERSONAL_ACHIEVEMENTS = 'game_lol_lc_widgets_post__game_PERSONAL_ACHIEVEMENTS',
  game_lol_lc_widgets_post__game_TEAM_DAMAGE_DEALT_TAKEN = 'game_lol_lc_widgets_post__game_TEAM_DAMAGE_DEALT_TAKEN',
  game_lol_lc_widgets_post__game_TEAM_KPI_METRICS = 'game_lol_lc_widgets_post__game_TEAM_KPI_METRICS',
  game_lol_lc_widgets_post__game_TOBII_METRICS = 'game_lol_lc_widgets_post__game_TOBII_METRICS',
  game_lol_lc_widgets_post__game_WARDS_OVERVIEW = 'game_lol_lc_widgets_post__game_WARDS_OVERVIEW',
  game_lol_lc_widgets_pre__game_CHAMPION_POWER_SPIKES = 'game_lol_lc_widgets_pre__game_CHAMPION_POWER_SPIKES',
  game_lol_lc_widgets_pre__game_COMBO_TRADING_AT_LANE = 'game_lol_lc_widgets_pre__game_COMBO_TRADING_AT_LANE',
  game_lol_lc_widgets_pre__game_DAMAGE_TYPE_DISTR = 'game_lol_lc_widgets_pre__game_DAMAGE_TYPE_DISTR',
  game_lol_lc_widgets_pre__game_GANK_OPPORTUNITIES = 'game_lol_lc_widgets_pre__game_GANK_OPPORTUNITIES',
  game_lol_lc_widgets_pre__game_HOW_TO_START = 'game_lol_lc_widgets_pre__game_HOW_TO_START',
  game_lol_lc_widgets_pre__game_ITEMS_RECOMMENDATION = 'game_lol_lc_widgets_pre__game_ITEMS_RECOMMENDATION',
  game_lol_lc_widgets_pre__game_PLAYING_AGAINST = 'game_lol_lc_widgets_pre__game_PLAYING_AGAINST',
  game_lol_lc_widgets_pre__game_PROMO_TOKENS_INTRO = 'game_lol_lc_widgets_pre__game_PROMO_TOKENS_INTRO',
  game_lol_lc_widgets_pre__game_SKILL_ORDER = 'game_lol_lc_widgets_pre__game_SKILL_ORDER',
  game_lol_lc_widgets_pre__game_SKILL_TO_FOCUS = 'game_lol_lc_widgets_pre__game_SKILL_TO_FOCUS',
  game_lol_lc_widgets_pre__game_TEAM_POWER_SPIKES = 'game_lol_lc_widgets_pre__game_TEAM_POWER_SPIKES',
  game_lol_referral_confirmation_status = 'game_lol_referral_confirmation_status',
  game_lol_referral_initial_modal_shown = 'game_lol_referral_initial_modal_shown',
  game_lol_summoner_name = 'game_lol_summoner_name',
  game_lol_summoner_profile_icon = 'game_lol_summoner_profile_icon',
  game_lol_summoner_region = 'game_lol_summoner_region',
  game_lol_tobii_isInstalled = 'game_lol_tobii_isInstalled',
  game_tft_team_comp_premium_filters_enabled = 'game_tft_team_comp_premium_filters_enabled',
  game_val_access_token = 'game_val_access_token',
  game_val_auth_token = 'game_val_auth_token',
  game_val_entitlements_token = 'game_val_entitlements_token',
  game_val_lesson_plans_rating = 'game_val_lesson_plans_rating',
  game_val_puuid = 'game_val_puuid',
  game_val_region = 'game_val_region',
  game_val_rso_identity_token = 'game_val_rso_identity_token',
  notification_MARKETING_NEWSLETTER_ALLOWED = 'notification_MARKETING_NEWSLETTER_ALLOWED',
  notification_MARKETING_PRODUCT_UPDATES_LOL_ALLOWED = 'notification_MARKETING_PRODUCT_UPDATES_LOL_ALLOWED',
}

export type SingUpMarketingParams = {
  channel?: InputMaybe<Scalars['String']>;
  game?: InputMaybe<Game>;
  lang?: InputMaybe<Languages>;
  platform?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['String']>;
  socialId?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<Themes>;
  version?: InputMaybe<Scalars['String']>;
};

export type SpendTokensResult = {
  __typename?: 'SpendTokensResult';
  account?: Maybe<Account>;
  done: Scalars['Boolean'];
  wallet?: Maybe<Wallet>;
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  /** Define how offent we will charge the user. */
  chargeType: ChargeType;
  /** Discount value in percent if any e.g 30 for 30% */
  discount?: Maybe<Scalars['Int']>;
  /** Plan duration if any. */
  duration?: Maybe<Duration>;
  /** Game is plan related game */
  game: Game;
  /** URL safe plan identifier. */
  id: Scalars['ID'];
  /** Internal (system) plan name */
  internalName: Scalars['String'];
  /** Localized plan name e.g Supporter Monthly */
  name: Scalars['String'];
  /** Define account level will be granted to the user. */
  planType: SubscriptionPlanType;
  /** Amount of money which will be charged from user every month before discount. */
  previousRecurringCharge?: Maybe<Amount>;
  /** Total amount of money which will be charged from user during whole subscription period before discount. */
  previousTotalCharge?: Maybe<Amount>;
  /** Provider is payment gateway */
  provider: PaymentGatewayKind;
  /** Amount of money which will be charged from user every month. */
  recurringCharge?: Maybe<Amount>;
  /** saleEndDate date when sales ends */
  saleEndDate?: Maybe<Scalars['String']>;
  /** Plan meta information. */
  tags?: Maybe<Array<SubscriptionPlanTag>>;
  /** Total amount of money which will be charged from user during whole subscription period. */
  totalCharge?: Maybe<Amount>;
};

export enum SubscriptionPlanTag {
  BEST_CHOICE = 'BEST_CHOICE',
  COLLECTOR_EDITION = 'COLLECTOR_EDITION',
  GIFT = 'GIFT',
  SALES = 'SALES',
  TRIAL = 'TRIAL',
}

export enum SubscriptionPlanType {
  ADFREE = 'ADFREE',
  CARRY = 'CARRY',
  LESSON = 'LESSON',
  SUPPORTER = 'SUPPORTER',
}

export type SubscriptionsResult = {
  __typename?: 'SubscriptionsResult';
  subscriptions?: Maybe<Array<SubscriptionPlan>>;
};

export enum Themes {
  CSGO = 'CSGO',
  DEFAULT = 'DEFAULT',
  DESTINY_2 = 'DESTINY_2',
  DIABLO_4 = 'DIABLO_4',
  LOL = 'LOL',
  LOR = 'LOR',
  LOST_ARK = 'LOST_ARK',
  OMEN = 'OMEN',
  TFT = 'TFT',
  VALORANT = 'VALORANT',
}

export type UpdatePaymentInfoResult = {
  __typename?: 'UpdatePaymentInfoResult';
  result: Scalars['Boolean'];
};

export type UserSubscriptionPlan = {
  __typename?: 'UserSubscriptionPlan';
  /** true if this subscription wil lbe cancelled */
  cancelAtPeriodEnd: Scalars['Boolean'];
  /** End subscription timestamp. */
  endStamp?: Maybe<Scalars['Int']>;
  /** Selected plan. */
  plan?: Maybe<SubscriptionPlan>;
  /** Start subscription timestamp. */
  startStamp?: Maybe<Scalars['Int']>;
};

export type Wallet = {
  __typename?: 'Wallet';
  balance: Array<Amount>;
  billingInfo?: Maybe<BillingInfo>;
  currSubscription?: Maybe<UserSubscriptionPlan>;
  history?: Maybe<Array<WalletOperation>>;
  nextSubscription?: Maybe<UserSubscriptionPlan>;
  subscriptions?: Maybe<SubscriptionsResult>;
};

export type WalletOperation = {
  __typename?: 'WalletOperation';
  amount: Amount;
  duration?: Maybe<Duration>;
  id: Scalars['ID'];
  planType: SubscriptionPlanType;
  timestamp: Scalars['Int'];
  type: WalletOperationType;
};

export enum WalletOperationType {
  LOL_LIVE_COMPANION = 'LOL_LIVE_COMPANION',
  SUBSCRIPTION_CHARGE = 'SUBSCRIPTION_CHARGE',
  TFT_IN_GAME_OVERLAY_PREMIUM = 'TFT_IN_GAME_OVERLAY_PREMIUM',
  TFT_TEAM_COMP_PREMIUM_FILTERS_24H = 'TFT_TEAM_COMP_PREMIUM_FILTERS_24H',
}
