import { CDN_IMAGES } from '../../constants';
import { AppThemeConfig } from '../../types/app.types';
import { Game } from '../../__generated/accounts/types';
import { Provider } from '../../types/provider';

export const config: AppThemeConfig = {
  gameName: Game.DIABLO_4,
  isPremiumSubscriptionAvailable: false,
  wallpaper: `${CDN_IMAGES}/backgrounds/desktop-onboarding-bg.png`,
  paymentWallpaper: null,
  paymentCongratulationWallpaper: null,
  testimonials: [
    {
      icon: '',
      name: 'Kripparian',
      text:
        "All of my builds are on hosted Mobalytics so if you're ever curious what I'm playing in this Season of Diablo 4, that is where you should be heading",
    },
    {
      icon: '',
      name: 'Bajheera',
      text: 'Mobalytics is my #1 source for trusted Diablo 4 builds, guides, and the latest meta rankings!',
    },
    {
      icon: '',
      name: 'DonTheCrown',
      text:
        "If you've downloaded the Overlay for Mobalytics, it just works right in the game and you can follow along with what's going on in the build just like that.",
    },
  ],
  primaryProviders: [Provider.Twitch, Provider.Google],
  fallbackRedirectUri: 'https://mobalytics.gg/diablo-4',
};
