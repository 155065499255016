import { CDN_IMAGES } from '../../constants';
import { AppThemeConfig } from '../../types/app.types';
import { Game } from '../../__generated/accounts/types';
import { Provider } from '../../types/provider';

export const config: AppThemeConfig = {
  gameName: Game.DEADLOCK,
  isPremiumSubscriptionAvailable: false,
  wallpaper: `${CDN_IMAGES}/backgrounds/desktop-onboarding-bg.png`,
  paymentWallpaper: null,
  paymentCongratulationWallpaper: null,
  testimonials: [],
  primaryProviders: [Provider.Twitch, Provider.Google],
  fallbackRedirectUri: 'https://mobalytics.gg/deadlock',
};
