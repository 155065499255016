import { CDN_IMAGES } from '../../constants';
import { AppThemeConfig } from '../../types/app.types';
import { Game } from '../../__generated/accounts/types';
import { Provider } from '../../types/provider';

export const config: AppThemeConfig = {
  gameName: Game.VALORANT,
  isPremiumSubscriptionAvailable: false,
  wallpaper: `${CDN_IMAGES}/backgrounds/desktop-onboarding-bg.png`,
  paymentWallpaper: `${CDN_IMAGES}/backgrounds/valorant-payment-bg.jpg`,
  paymentCongratulationWallpaper: `${CDN_IMAGES}/backgrounds/tft-payment-congratulation-bg.jpg`,
  testimonials: [
    {
      icon: 'https://cdn.mobalytics.gg/assets/valorant/images/map-advices/misc-tags/t1.svg',
      name: 'Joe Marsh, CEO of T1',
      text:
        'We are especially excited to collaborate with the Mobalytics team to help build out new training programs for Valorant so we can continue to see talent develop and grow within Riot’s new title.',
    },
  ],
  primaryProviders: [Provider.Google, Provider.Discord],
  fallbackRedirectUri: 'https://mobalytics.gg/',
};
