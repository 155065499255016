export enum Theme {
  Default = 'default',
  Lol = 'lol',
  Omen = 'omen',
  Csgo = 'csgo',
  Bacon = 'bacon',
  Lor = 'lor',
  Tft = 'tft',
  Valorant = 'valorant',
  LostArk = 'lost-ark',
  Destiny2 = 'destiny-2',
  Diablo4 = 'diablo-4',
  Deadlock = 'deadlock',
  Poe2 = 'poe-2',
  TheBazaar = 'the-bazaar',
}
